<template>
<div id="box">
<div class="header">
  <div>
    <img src="../assets/img/1151c0d724082bf461eba35adb904017.png">
  </div>
  <div class="homeBtn" @click="toHome">
    首页
  </div>
</div>
  <div>
    <img style="height: 281px;width: 100vw;margin-top: 62px" src="../assets/img/bg1.png">
  </div>
  <div id="body1" class="bodyBox">
    <div>
      <div class="title">一、阳光收费：全包型服务费，帮客户省钱</div>
      <div class="normalText">
        大大团房收费标准：
      </div>
      <div class="normalText">
        居间代理费:
      </div>
      <div class="normalText">
        房屋成交总价的1.5%，其中买房客户支付0.75%，卖房客户支付0.75%；
      </div>
      <div class="normalText">
        按揭服务费:
      </div>
      <div class="normalText">
        大大团房不收取按揭服务费，具体以银行或金融机构的政策为准。
      </div>

      <div class="otherText" style="margin-top:19px;">
        行业收费标准：
      </div>
      <div class="otherText">
        居间代理费:
      </div>
      <div class="otherText">
        房屋成交总价的3%，其中买房客户支付1.5%，卖房客户支付1.5%；
      </div>
      <div class="otherText">
        按揭服务费:
      </div>
      <div class="otherText">
        按客户贷款额1%收取，由买房客户支付。
      </div>
    </div>
  </div>
  <div id="body2" class="bodyBox">
    <div>
      <div class="title">二、全程保障：一站式标准服务，交易安全无忧</div>
      <div class="link">
        安心钥匙托管——房屋验真实勘——房源全免推广——邻里伙伴带看——居住生活规划——交易法律顾问<br/>——标准签约合同——资金监管保障——产权过户陪同——房屋交割陪同——邻里生活引荐
      </div>
    </div>
  </div>
  <div id="body3" class="bodyBox">
    <div>
      <div class="title">三、卖房服务：6大自媒体矩阵联动推广，帮客户快速卖房</div>
      <div>
        <img style="width: 674px;height: 169px" src="../assets/img/media.png" />
      </div>
    </div>
  </div>
  <div id="body4" class="bodyBox">
    <div>
      <div class="title">四、买房服务：5大贴心服务体系，买房安全专业省心</div>
      <div style="width: 674px">
        <div class="text">一站式服务，安全优选</div>
        <div class="subText">大大团房为买房客户提供一站式服务，从需求测评、房源匹配、邻里带看、交易服务、物业交割、生活规划多个维度提供一站式服务，是客户置业的安全优选平台。</div>
        <div class="text">房东直聊，避坑优选</div>
        <div class="subText">大大团房提供买房客户房源验真、房屋实勘、产权核验、风险排查等前期工作确保房屋信息清晰透明，依托邻里带看、直聊房东、律师服务等保障措施避免买房客户踩坑。</div>
        <div class="text">深度需求测评，高效优选</div>
        <div class="subText">大大团房切实从买房客户需求角度出发，根据客户实际情况，为客户提供深度需求分析，为客户匹配符合需求的优质房源，并为客户提供详细置业规划，帮助客户实现高效优选。</div>
        <div class="text">多维价格比对，实惠优选</div>
        <div class="subText">价格始终是客户最关心的问题之一，大大团房为买房客户提供详细的多维度价格比对，从历史成交、竞品对比、未来趋势、价格评估等多方面为客户提供价格建议及支持，帮助客户实现实惠优选。</div>
        <div class="text">多维价格比对，实惠优选</div>
        <div class="subText">买房不只是买房，更是一次生活的重新规划。大大团房为买房客户快速融入新社区新生活，从房屋使用说明、生活规划指引、邻里社区引荐等多方面为客户提供助力，帮助客户快乐安居，实现居住优选。</div>
      </div>
    </div>
  </div>
  <div id="body5" class="bodyBox">
    <div>
      <div class="title">五、团队支持：资深房产专家+强大技术团队+邻居服务邻居</div>
      <img style="width: 674px;height: 144px" src="../assets/img/team.png" />
    </div>
  </div>
</div>
</template>

<script setup>
import { useRouter,useRoute } from 'vue-router'
import {onMounted} from 'vue';
const router = useRouter()
const route = useRoute()
onMounted(() =>{
  let type = route.query.type;
  const classEle = document.getElementById(`body` + type);
  if(classEle){
    classEle.scrollIntoView({
      behavior: 'smooth',
      block: "center",
      inline: "center",});
  }
})
const toHome = () =>{
  router.push({
    name : 'index'
  })
}
</script>

<style scoped lang="scss">
#box{
  width: 100vw;
  height: 100%;
  overflow: scroll;
}
.header{
  background: #fff;
  width: 100vw;
  height: 62px;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  .homeBtn{
    cursor: pointer;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 10px;
    color: #222222;
    line-height: 40px;
  }
  .homeBtn:hover{
    color: #E60013;
  }
  img{
    width: 127px;
    height: 24px;
    margin-right: 68px;
    margin-left: 108px;
  }
}
.title{
  width: 674px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 17px;
  color: #B00000;
  line-height: 51px;
}
.bodyBox{
  margin-bottom: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#body1{
  .normalText{
    font-family: Microsoft YaHei;
    font-weight: 300;
    font-size: 14px;
    color: #222222;
    line-height: 23px;
  }
  .otherText{
    font-family: Microsoft YaHei;
    font-weight: 300;
    font-size: 14px;
    color: #999999;
    line-height: 23px;
  }
}
#body4{
  .text{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 17px;
    color: #222222;
    line-height: 51px;
  }
  .subText{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #999999;
    line-height: 23px;
  }
}
.link{
  width: 674px;
  height: 36px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  font-size: 14px;
  color: #222222;
}
</style>
