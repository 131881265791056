<template>
<div id="box">
<div class="header">
  <img src="../assets/img/1151c0d724082bf461eba35adb904017.png">
  <div class="homeBtn" @click="toHome">
    首页
  </div>
</div>
  <div>
    <img class="partnerBg" src="../assets/img/partner.png">
  </div>
  <div class="videoContent">
    <video class="video" autoplay muted controls src="https://greatly.51tf.cn/h5/xuanchuanpian.m4v"></video>
  </div>
  <div id="body1" class="bodyBox">
    <div>
      <div class="title">我们坚持做传递美好的事</div>
      <div class="text">
        房子终究是用来装载生活的，大大团房的宗旨，就是帮助买房人找到适合自己居住的房子，“想你所想，用爱安家”。
      </div>
      <div class="text">作为邻里伙伴的你需要以朋友的角度，向买房人真实表达所在街区的居住体验、介绍房屋的情况、介绍小区的情况，在买卖双方之间充分、真诚沟通，传递美好！</div>
      <div class="text">
        我们反对为了销售房屋，却忽视房屋对买房人的适用性。
      </div>
      <div class="text">
        <span class="boldText">大大团房的邻里伙伴是从心开始，是做买房人的好邻居、好伙伴。</span>
        所以，你身边的朋友，会越来越多，你的收益和收获也就越来越多。
      </div>
    </div>
  </div>
  <div id="body2" class="bodyBox">
    <div>
      <div class="title">我们坚持做挑战自我的事</div>
      <div class="boldText">
        大大团房会为邻里伙伴们提供免费的专业培训和实战带教，平台是你最可靠的后盾，但你仍需具备以下条件：
      </div>
      <div class="text">
        （1）28-65岁之间，男女不限；<br />（2）大专以上学历，在工作区域内拥有自有产权住房；<br />（3）有助人热心，善于沟通和协调，愿意与人交流；<br />（4）有责任心，发现买卖双方需求或问题，能主动想办法解决；<br />（5）愿意主动学习、与平台共成长；<br />（6）熟练使用微信，会拍照、制作短视频那就更厉害啦！
      </div>
    </div>
  </div>
  <div id="body3" class="bodyBox">
    <div>
      <div class="title">我们坚持做建立标准的事</div>
      <div>
        <div class="text">
          <span class="boldText">1、将买房者当做朋友：</span>
          陪同客户看房，介绍自己生活所在街区的生活环境和周边配套情况，介绍房源小区的情况，并解答客户疑问。
        </div>
        <div class="text">
          <span class="boldText">2、不强力推销：</span>
          站在朋友角度，把真实客观的信息反馈给买家，买家需要决策建议的，我们再给出选择建议。绝不为了成交而推销不适合买家的房源或明显有缺陷的房源。
        </div>
        <div class="text">
          <span class="boldText">3、接受业主委托并维护房源：</span>
          对于房东，我们必须阐明不炒房不赚差价的要求，以维护房东利益，每周与房东沟通带看情况，并更新售价及房东要求等信息；
        </div>
        <div class="text">
          <span class="boldText">4、充分展示大大团房平台：</span>
          在日常带看及房源维护过程中，对平台交易安全优势、真诚合作理念及全程专业服务内容，进行全方位的展示和推广；
        </div>
        <div class="text">
          <span class="boldText">5、真诚贯穿房屋交流全流程：</span>
          买卖双方是存在利益博弈的，你需尽可能保持客观及专业，以真诚的态度做好带看、房源维护、成交促成、房屋交接等全流程的跟进；
        </div>
        <div class="text">
          <span class="boldText">6、最好的成交是从合作到朋友：</span>
          买家入住，不是交易的结束，而是朋友交往的开始，买家入住前，提供生活配套设施介绍，帮助新业主快速融入新房的生活；
        </div>
        <div class="text">
          <span class="boldText">7、遵守自己与大大团房平台的其他后续补充约定（如有）。</span>
        </div>
      </div>
    </div>
  </div>
  <div id="body4" class="bodyBox">
    <div>
      <div class="title">我们坚持做有回报的事</div>
      <div class="customWidth">
       <div class="boldText">
         一分耕耘，一分收获，大大团房坚信和坚持有付出更有回报！
       </div>
        <div class="text">
          1、新客每次带看房源，给予20元的奖励费用，二访没有哦，日结。<br />2、主动发掘房源并上架，奖励上架费50元/套，日结；该房源成功销售后，奖励总房款的1‰，月结；<br />3、主动发掘买房客户，并上报客户需求，奖励50元/条，日结；该客户成交后奖励总房款的1.5‰，月结；<br />4、负责带看撮合成交的，奖励总房款的2‰，月结；<br />5、以上2、3、4项可叠加。
        </div>
        <div class="boldText tips">
          Tips：以一套房源成交总价100万元计，发掘房源成交可获奖励1000元，发掘客源成交可获奖励1500元，负责撮合成交可获奖励2000元，三类奖励可叠加，如房源、客源、成交归属同一邻里伙伴，则可获奖励4500元！】
        </div>
      </div>
    </div>
  </div>
  <div class="floatBtn" v-if="isShow">
    <img @click="closeFloatBtn" class="close" src="../assets/img/f34eb43f157f90caacd344b8c9c206ff.png"/>
    <img class="code" src="../assets/img/qrcode.jpg">
    <div class="bottomText">
      微信扫码报名
    </div>
  </div>
</div>
</template>

<script setup>
import { useRouter,useRoute } from 'vue-router'
import {ref , onMounted} from 'vue';
const router = useRouter()
const route = useRoute()
onMounted(() =>{
  let type = route.query.type;
  const classEle = document.getElementById(`body` + type);
  if(classEle){
    classEle.scrollIntoView({
      behavior: 'smooth',
      block: "center",
      inline: "center",});
  }
})
const toHome = () =>{
  router.push({
    name : 'index'
  })
}
const closeFloatBtn = () =>{
  isShow.value = false;
}
const isShow = ref(true)
</script>

<style scoped lang="scss">
#box{
  width: 100vw;
  height: 100%;
  overflow: scroll;
}
.header{
  background: #fff;
  width: 100vw;
  height: 62px;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  .homeBtn{
    cursor: pointer;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 10px;
    color: #222222;
    line-height: 40px;
  }
  .homeBtn:hover{
    color: #E60013;
  }
  img{
    width: 127px;
    height: 24px;
    margin-right: 68px;
    margin-left: 108px;
  }
}
.title{
  width: 674px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 17px;
  color: #008462;
  line-height: 51px;
}
.bodyBox{
  margin-bottom: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#body1{
  .normalText{
    font-family: Microsoft YaHei;
    font-weight: 300;
    font-size: 14px;
    color: #222222;
    line-height: 23px;
  }
  .otherText{
    font-family: Microsoft YaHei;
    font-weight: 300;
    font-size: 14px;
    color: #999999;
    line-height: 23px;
  }
}
.text{
  width: 674px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  font-size: 14px;
  color: #999999;
  line-height: 23px;
}
.customWidth{
  width: 674px;
}
.boldText{
  font-family: Microsoft YaHei;
  font-weight: 300;
  font-size: 14px;
  color: #222222;
  line-height: 23px;
}
.tips{
  margin-top: 25px;
  font-weight: bold;
}
.link{
  width: 674px;
  height: 36px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  font-size: 14px;
  color: #222222;
}
.videoContent{
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 371px;
  margin-top: 19px;
  margin-bottom: 44px;
  .video{
    width: 675px;
    height: 371px;
  }
}
.floatBtn{
  width: 146px;
  height: 180px;
  background: #FFFFFF;
  box-shadow: 0 1px 11px 0 rgba(0,0,0,0.1);
  border-radius: 11px;
  position: fixed;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
  flex-direction: column;
  top: 100px;
  .bottomText{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 15px;
    color: #222222;
    line-height: 51px;
  }
  .code{

    width: 116px;
    height: 112px;
  }
  .close{
    position: absolute;
    top: 8px;
    right: 8px;
    width: 12px;
    height: 12px;
    cursor: pointer;
  }
}
.partnerBg{
  height: 281px;
  width: 100vw;
  margin-top: 62px
}
</style>
