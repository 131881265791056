<template>
<div id="home">
  <div class="header">
    <img src="../assets/img/1151c0d724082bf461eba35adb904017.png">
    <div style="display: flex;gap: 33px">
      <div @click="menuClick(item.class)" v-for="(item , index) in menus" :key="index" class="menu">
        <span>{{item.name}}</span>
        <div :class="item.isSelected ? 'dot': 'unDot'"></div>
      </div>
    </div>
  </div>
  <div id="body1">
    <div class="mainBox">
      <div style="display: flex;flex-direction: column;justify-content: center;margin-right: 92px;">
        <div>
          <div class="title">大大团房</div>
          <div class="title">
            租售买好房 团房省一半
          </div>
        </div>
        <div class="desc">
          买房人与卖房人自主交易的平台
        </div>
        <div class="downloadText">
          应用下载
        </div>
        <div class="downloadBtn">
          <img @click="androidClick" src="../assets/img/1f4917a657a6cf81e59899707605fe72.png">
          <img @click="iosClick" src="../assets/img/8daa60ef6dff6829506d6c49ff031e80.png">
        </div>
      </div>
      <div>
        <img style="width: 349px;height: 397px" src="../assets/img/94ed1d60c584fa086ffdb8b8748e472f.png">
      </div>
    </div>
    <div class="bottomBox">
      <div v-for="(item , index ) in services" :key="index" class="serviceItem">
        <img :width="item.width" :height="item.height" :src="item.icon">
        <div class="iconName">{{item.name}}</div>
        <div class="desc">{{item.desc}}</div>
      </div>
    </div>
  </div>
  <div id="body2">
    <div class="title">— 关于大大团房 —</div>
    <div class="desc">About Us</div>
    <div class="mainBox">
      <div>
        <img width="263px" height="216px" src="../assets/img/a1df17c28e0f1d9394c4d92b96d427ff.png">
      </div>
      <div class="textBox">
        <div style="color: #666666;">大大团房是买房人和卖房人自己的平台，我们希望在这里能进行真诚交易、房屋生活的体验分享、所在区域发展的前景分享。建立友好公平、阳光透明的交易平台、生活平台、交友平台、发展平台。</div>
        <div style="color: #E60013;">放大交流，成就你我。</div>
      </div>
    </div>
  </div>
  <div id="body3">
    <div class="title">— 业务板块 —</div>
    <div class="desc">Business Sector</div>
    <div class="mainBox">
      <img width="145px" height="113px" src="../assets/img/df3c1dca4fdfa258f4d46a276896d431.png">
      <img width="145px" height="113px" src="../assets/img/619de6b8b50e0db1471da3fe1a9ead34.png">
      <img width="145px" height="113px" src="../assets/img/11b07e720989005139822ca09cb63bfa.png">
      <img width="145px" height="113px" src="../assets/img/zufang.png">
    </div>
  </div>
  <div id="body4">
    <div class="title">— 平台优势 —</div>
    <div class="desc">Platform Advantages</div>
    <div class="mainBox">
      <div @click="platformClick(1)">
        <img width="43px" height="47px" src="../assets/img/13d21327c4abb441caaa2f38be192b71.png">
        <span class="span1">阳光收费</span>
        <span class="span2">全包型服务费，帮客户 省钱</span>
        <span class="span3">>>></span>
      </div>
      <div @click="platformClick(2)">
        <img width="44px" height="47px" src="../assets/img/f0832771880923812f475ce446845928.png">
        <span class="span1">全程保障</span>
        <span class="span2" >一站式标准服务，交易 安全无忧</span>
        <span class="span3">>>></span>
      </div>
      <div @click="platformClick(3)">
        <img width="51px" height="51px" src="../assets/img/163981e7d999479198b41d7cd5286282.png">
        <span class="span1">无忧卖房</span>
        <span class="span2">6大自媒体矩阵联动推广 帮客户快速卖房</span>
        <span class="span3">>>></span>
      </div>
      <div @click="platformClick(4)">
        <img width="42px" height="52px" src="../assets/img/795a9b6e6998f805ac716aca1f23006f.png">
        <span class="span1">安心买房</span>
        <span class="span2">5大贴心服务体系，买 房安全专业省心</span>
        <span class="span3">>>></span>
      </div>
      <div @click="platformClick(5)">
        <img width="52px" height="47px" src="../assets/img/e0b1f5ec83d4e99a83b0adf2a8c87d2a.png">
        <span class="span1">团队支持</span>
        <span class="span2">资深房产专家+强大技术 团队+邻居服务邻居</span>
        <span class="span3">>>></span>
      </div>
    </div>
  </div>
  <div id="body5">
    <div class="title">— 加入邻里伙伴 —</div>
    <div class="desc">Join Neighborhood Partners</div>
    <div class="mainBox">
      <div class="leftBox">
        <div class="textBox">
          <span>Hi，亲爱的邻里伙伴，大大团房在坚持做一件美好的事，让房屋交易变的更愉快，让美好生活在业主与业主、邻居与邻居之间快乐传递！所以，大大团房需要你，你的邻居需要你！</span>
          <span class="joinSpan" @click="joinPartner">立即加入></span>
        </div>
        <div class="imgBox">
          <img width="90px" height="90px" src="../assets/img/acf492371911816d612da89fa2c315a9.png">
          <img width="90px" height="90px" src="../assets/img/2f90398edc313675284bbdfde55746ef.png">
          <img width="90px" height="90px" src="../assets/img/b61e31cd42917e0efbe11eca23f64de3.png">
          <img width="90px" height="90px" src="../assets/img/b791bebcc15fb8c36bc7fdbff3edb226.png">
        </div>
      </div>
      <img width="271px" height="217px" src="../assets/img/2e299612f7b91fac30b59be15aa704f6.png">
    </div>
    <div class="copyRight">
      <div>湖南省优选家信息科技有限公司  |  地址：长沙市望城区金星北路港湘铂玥2520室</div>
      <div>电话：19311684497  |  邮箱：2260660858@qq.com  |  QQ：2260660858</div>
      <div style="margin-top: 25px">Copyright © 2024 湖南省优选家信息科技有限公司 51tf.cn</div>
      <div>版权所有备案号:湘ICP备2024040927号-1</div>
    </div>
  </div>
  <div class="bottomPadding">

  </div>
  <div class="footer">

    <div class="footerChild">
      <img class="phone" src="../assets/img/72fd7dcc6b101596095d9a3f7a0fd64b.png" />
      <div style="display: flex;flex-direction: column;margin-right: 52px">
        <span class="logoName">大大团房</span>
        <span class="descName">租售买好房 团房省一半</span>
      </div>
      <div class="qrCodeBox">
        <div class="qrCode">

          <el-image class="img" hide-on-click-modal	 close-on-press-escape	 :src="require('../assets/img/c6f64f723f711f3547fe1cb91573f23a.png')"
                    :preview-src-list="[require('../assets/img/c6f64f723f711f3547fe1cb91573f23a.png')]" />
          <span>微信公众号</span>
        </div>
        <div class="qrCode">
          <el-image class="img" hide-on-click-modal	close-on-press-escape	 :src="require('../assets/img/036f784ff8f51c460267d2158fa350e6.png')"
                    :preview-src-list="[require('../assets/img/036f784ff8f51c460267d2158fa350e6.png')]" />
          <span>微信小程序</span>
        </div>
        <div class="qrCode">
          <el-image class="img" hide-on-click-modal	close-on-press-escape	 :src="require('../assets/img/396d3abb499bf9f30cbbf142ab9a6762.png')"
                    :preview-src-list="[require('../assets/img/396d3abb499bf9f30cbbf142ab9a6762.png')]" />
          <span>抖音小程序</span>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import {ref, onMounted} from 'vue';
const router = useRouter()
const menus = ref([{
  name : '下载APP',
  isSelected : true,
  class: 'body1'
},
  {
    name : '关于大大团房',
    isSelected : false,
    class: 'body2'
  },
  {
    name : '业务板块',
    isSelected : false,
    class: 'body3'
  },
  {
    name : '平台优势',
    isSelected : false,
    class: 'body4'
  },
  {
    name : '加入邻里伙伴',
    isSelected : false,
    class: 'body5'
  }])

const services = ref([{
  name : '真房源',
  icon : require("../assets/img/f6019511faf7a5ef20725d484e933e2e.png"),
  desc: 'Real housing',
  height : '34px',
  width : '34px'
},
  {
    name : '真省钱',
    icon :  require("../assets/img/0a0476ab2c02112482f8846142d7776d.png"),
    desc: 'Real save money',
    height : '34px',
    width : '34px'
  },
  {
    name : '真安全',
    icon :  require("../assets/img/077f41c8b3c038f433ef4cab7ffd1232.png"),
    desc: 'Real safe',
    height : '29px',
    width : '34px'
  },
  {
    name: '真高效',
    icon :  require("../assets/img/a311096ef93ff70aa0aaed3fae53c5a5.png"),
    desc: 'Real efficient',
    height : '34px',
    width : '34px'
  },
  {
    name : '真贴心',
    icon :  require("../assets/img/01193614dfb301eff62f504ff347adc9.png"),
    desc: 'Real considerate',
    height : '34px',
    width : '34px'
  }])



onMounted(() => {
  if (navigator.userAgent.indexOf("iPhone") > -1 ||navigator.userAgent.indexOf("Android") > -1){
    window.location.href ="https://www.51tf.cn/mobile";
    return
  }
  window.addEventListener('scroll', function(e) {
    let menuItem = null;
    console.log(e)
    for (let i = 0; i < menus.value.length; i++) {
      let it = JSON.parse(JSON.stringify(menus.value[i]));
      const body = document.getElementById(it.class)
      if (isElementInViewport(body)){
        menuItem = it
        break;
      }
    }
    if(menuItem){
      menus.value.forEach(it => {
        if(it.class === menuItem.class){
          it.isSelected = true;
        }else{
          it.isSelected = false;
        }
      })
    }

  });
});

// 创建并初始化IntersectionObserver
const platformClick = (type) =>{
  router.push({
    path : '/platform',
    query : {
      type : type
    }
  })
}
const joinPartner = ()=>{
  router.push({
    path : '/partner',
  })
}
const menuClick = (item) =>{
  const classEle = document.getElementById(item);
  classEle.scrollIntoView({ behavior: 'smooth' });
  menus.value.forEach(it => {
    if(it.class === item){
      it.isSelected = true;
    }else{
      it.isSelected = false;
    }
  })
}
const iosClick = () =>{
  window.location.href = 'https://apps.apple.com/app/%E5%A4%A7%E5%A4%A7%E5%9B%A2%E6%88%BF/id6480157442';
}

const androidClick = () =>{
  window.location.href = "https://www.51tf.cn/apk/dadatuanfang.apk"
}
const isElementInViewport = (el) => {
  var rect = el.getBoundingClientRect();
  return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}


</script>

<style scoped lang="scss">

#home{
  overflow: scroll;
  background-color: #f3f3f3;
  width: 100vw;
  height: 100%;
  background-image: url("../assets/img/0c2835129179e50a15287ac8ac7e2abe.png");
  background-size: 100vw 645px;
  background-repeat: no-repeat;
}
.footer{
  width: 100%;
  height: 133px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: fixed;
  bottom: 0;
  .phone{
    width: 110px;
    height: 133px;
    position: relative;
    bottom: 20px;;
  }
  .footerChild{
    height: 101px;
    background: #fff;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .logoName{
      width: 100px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      font-size: 24px;
      color: #222222;
      line-height: 28px;
    }
    .descName{
      width: 119px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      font-size: 11px;
      color: #999999;
      line-height: 28px;
    }
  }
  .qrCodeBox{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 25px;
    .qrCode{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .img{
        width: 56px;
        height: 56px;
      }
      span{
        width: 47px;
        height: 9px;
        font-family: Microsoft YaHei;
        font-weight: 300;
        font-size: 9px;
        color: #999999;
        line-height: 28px;
      }
    }
  }
}
.header{
  position: fixed;
  top: 0;
  height: 50px;
  display: flex;
  align-items: center;
  img{
    width: 127px;
    height: 24px;
    margin-right: 68px;
    margin-left: 108px;
  }
  .menu{
    cursor: pointer;
    gap:4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span{
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 10px;
      color: #222222;
    }
    span:hover{
      color: #E60013;
    }
    .dot{
      width: 12px;
      height: 2px;
      background: #E60013;
      border-radius: 1px;
    }
    .unDot{
      width: 12px;
      height: 2px;
    }
  }
}
#body1{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 102px;
  .mainBox{
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    .title{
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 37px;
      color: #222222;
    }
    .desc{
      margin-top: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 17px;
      color: #666666;
    }
    .downloadText{
      ont-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 8px;
      color: #222222;
      margin-top: 84px;
    }
    .downloadBtn{
      display: flex;
      gap: 3px;
      margin-top:7px;
      img{
        width: 115px;
        height: 59px;
        cursor: pointer;
      }
    }
  }
  .bottomBox{
    margin-top: 42px;
    width: 675px;
    height: 113px;
    background: #FFFFFF;
    box-shadow: 0 3px 17px 0 rgba(0,0,0,0.1);
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap : 57px;
    .serviceItem{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap:6px;
      img{
        width: 34px;
        height: 34px;
      }
      .iconName{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
      }
      .desc{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 8px;
        color: #999999;
      }
    }
  }

}
#body2{
  padding-top: 112px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .title{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 25px;
    color: #222222;
    line-height: 51px;
  }
  .desc{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #999999;
    line-height: 51px;
  }
  .mainBox{
    gap: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .textBox{
    font-family: Microsoft YaHei;
    font-weight: 300;
    font-size: 14px;
    line-height: 34px;
    width: 380px
  }
}
#body3{
  padding-top: 112px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .title{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 25px;
    color: #222222;
    line-height: 51px;
  }
  .desc{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #999999;
    line-height: 51px;
  }
  .mainBox{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
    margin-top: 12px;
  }
}

#body4{
  padding-top: 112px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .title{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 25px;
    color: #222222;
    line-height: 51px;
  }
  .desc{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #999999;
    line-height: 51px;
  }
  .mainBox{
    margin-top: 18px;
    display: flex;
    gap: 20px;
    div{
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      width: 122px;
      height: 188px;
      background: #FFFFFF;
      box-shadow: 0 1px 11px 0 rgba(0,0,0,0.1);
      border-radius: 17px;
      cursor: pointer;
      .span1{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #222222;
      }
      .span2{
        width: 103px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 10px;
        color: #999999;
      }
      .span3{
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 8px;
        color: #999999;
      }
    }
  }
}

#body5{
  padding-top: 112px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .title{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 25px;
    color: #222222;
  }
  .desc{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #999999;
    line-height: 51px;
  }
  .copyRight{
    margin-top: 104px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    div{
      font-family: Microsoft YaHei;
      font-weight: 300;
      font-size: 12px;
      color: #666666;
    }
  }
  .mainBox{
    display: flex;
    gap: 26px;
    margin-top: 7px;
    .leftBox{
      display: flex;
      flex-direction: column;
    }
    .imgBox{
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
    .textBox{
      width: 363px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      font-size: 14px;
      color: #666666;
      line-height: 34px;
      .joinSpan{
        font-family: Microsoft YaHei;
        font-weight: 300;
        font-size: 14px;
        color: #999999;
        cursor: pointer;
      }
      .joinSpan:hover{
        text-decoration: underline;
        text-underline: #E60013;
        color: #E60013;
      }
    }
  }
}
.bottomPadding{
  height: 133px;
}
</style>
